<template>
  <div v-if="shops">
    <CCard
      color="white"
      text-color="black"
      class="text-center col-md-12 shadow-sm"
    >
      <CCard>
        <CCardBody>
          <br />
          <CRow>
            <CCol md="12">
              <CRow>
                <CCol md="10">
                  <h2 class="font-weight-bold text-left">{{ $t('addMenber') }}</h2>
                </CCol>
                <CCol md="2" class="text-right">
                  <a @click="$router.go(-1)">
                    <CButton>
                      <CIcon name="cil-arrow-thick-from-right" />
                      {{ $t('back') }}
                    </CButton>
                  </a>
                </CCol>
              </CRow>
              <hr />
              <p class="text-danger font-weight-bold text-left">
                {{ validateAlert }}
              </p>
              <p class="text-danger font-weight-bold text-left">
                {{ errors }}
              </p>
              <CAlert
                v-if="successAlert !== ''"
                color="success"
                :show="showtime"
              >
                {{ successAlert }}
              </CAlert>
              <br />
              <CRow>
                <CCol md="6">
                  <div class="form-group row">
                    <label
                      class="col-sm-4 col-form-label text-left font-weight-bold"
                    >
                      {{ $t('tel') }} <strong class="text-danger">*</strong>
                    </label>
                    <div class="col-sm-8">
                      <VuePhoneNumberInput
                        v-model="phone"
                        default-country-code="TH"
                        @update="updatePhoneNumber"
                      />
                    </div>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <div class="form-group row">
                    <label
                      class="col-sm-4 col-form-label text-left font-weight-bold"
                    >
                      {{ $t('fullName') }} <strong class="text-danger">*</strong>
                    </label>
                    <div class="col-sm-8">
                      <input class="form-control" v-model="name" />
                    </div>
                  </div>
                </CCol>
                <CCol md="6">
                  <div class="form-group row">
                    <label
                      class="col-sm-4 col-form-label text-left font-weight-bold"
                    >
                      {{ $t('taxID') }}
                    </label>
                    <div class="col-sm-8">
                      <input class="form-control" v-model="taxID" />
                    </div>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12">
                  <div class="form-group row">
                    <label
                      class="col-sm-2 col-form-label text-left font-weight-bold"
                    >
                    {{ $t('address') }} <strong class="text-danger">*</strong>
                    </label>
                    <div class="col-sm-10">
                      <textarea class="form-control" v-model="address" />
                    </div>
                  </div>
                </CCol>
              </CRow>

              <CRow>
                <CCol md="6">
                  <div class="form-group row">
                    <label
                      class="col-sm-4 col-form-label text-left font-weight-bold"
                    >
                      {{ $t('postalCode') }}
                    </label>
                    <div class="col-sm-8">
                      <input class="form-control" v-model="postalCode" />
                    </div>
                  </div>
                </CCol>
                <CCol md="6">
                  <div class="form-group row">
                    <label
                      class="col-sm-4 col-form-label text-left font-weight-bold"
                    >
                      {{ $t('email') }}
                    </label>
                    <div class="col-sm-8">
                      <input class="form-control" v-model="email" />
                    </div>
                  </div>
                </CCol>
              </CRow>
              <hr />
              <CRow>
                <CCol md="12">
                  <CButton
                    block
                    color="success"
                    class="px-4 text-white"
                    @click="validate()"
                  >
                    {{ $t('saveInformation') }}
                  </CButton>
                </CCol>
              </CRow>
              <br />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCard>

    <CRow>
      <CModal
        :show.sync="popupModal"
        color="success"
        :title="this.$i18n.t('saveInformation')"
        :footer="footer"
        centered
      >
        <h5 class="text-center">
          <strong>{{ $t('confirmSaveMember') }}</strong>
        </h5>
        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="4">
              <CButton
                color="success"
                block
                @click="saveMember()"
                v-if="loadingButton === false"
              >
              {{ $t('comfirm') }}
              </CButton>
              <CButton
                color="success"
                block
                v-else-if="loadingButton === true"
                disabled
              >
                <CSpinner color="white" size="sm" />
              </CButton>
            </CCol>
            <CCol col="4">
              <CButton color="dark" @click="popupModal = false" block>
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
          <br />
        </template>
      </CModal>
    </CRow>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pos from '@/services/pos'
import NoShop from '@/containers/NoShop.vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  components: { NoShop, VuePhoneNumberInput },
  data() {
    return {
      name: '',
      taxID: '',
      address: '',
      postalCode: '',
      phone: '',
      email: '',
      colorCode: '',
      popupModal: false,
      footer: '',
      validateAlert: '',
      showtime: 3,
      loadingButton: false,
      successAlert: '',
      errors: '',
      phoneNumber: '',
      isPhoneValid: false,
    }
  },
  computed: {
    ...mapGetters(['shops']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
  },
  methods: {
    updatePhoneNumber(data) {
      this.phoneNumber = data.formatNational
      this.isPhoneValid = data.isValid
    },
    validate() {
      this.phone = this.phoneNumber.replace(/\s/g, '')

      if (this.name === '' || this.phone === '' || this.address === '') {
        this.validateAlert = this.$i18n.t('validateInfomation')
      } else {
        this.validateAlert = ''
        if (this.isPhoneValid === false) {
          this.errors = this.$i18n.t('validateTel')
        } else {
          if (this.email !== '') {
            if (!this.validEmail(this.email)) {
              this.errors = this.$i18n.t('validateEmail')
            } else {
              this.validateAlert = ''
              this.popupModal = true
              this.errors = ''
            }
          } else if (this.email === '') {
            this.validateAlert = ''
            this.popupModal = true
            this.errors = ''
          }
        }
      }
    },
    saveMember() {
      this.loadingButton = true
      const uid = this.uid

      let data = {
        name: this.name,
        taxID: this.taxID,
        address: this.address,
        postalCode: this.postalCode,
        phone: this.phone,
        email: this.email,
        shopObjectId: this.shopObjectId,
      }

      if (data.name === '' || data.phone === '' || data.address === '') {
        this.popupModal = false
      } else {
        pos({
          method: 'post',
          url: '/api/v1.0/' + uid + '/Shop/createmember/data',
          data: data,
        })
          .then((res) => {
            let objectId = res.data.data.objectId
            this.loadingButton = false
            this.popupModal = false
            this.successAlert = this.$i18n.t(saveSuccess)
            this.$router.push('/member/' + objectId + '/getdetail')
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    validEmail(email) {
      let EMAILREG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return EMAILREG.test(email)
    },
  },
}
</script>
